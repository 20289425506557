<template>
  <div class="board" id="test">
    <ConfirmModal v-if="this.confirmModal" :id="this.id" />
    <AdvancedSearchModal v-if="this.advancedModal" />
    <!--<button @click="sortTable()">Trier par "Forme graphique Corse"</button> -->
    <div class="boardControl">
     <v-icon>mdi-magnify</v-icon>
      <v-text-field
        class="searchInput"
        v-model="input"
        placeholder="Forme graphique Corse"
      />
      <v-btn class="btnText btnColor" elevation="0" color="#E65100" small @click="filterTableBySelection()">
        Rechercher dans la selection
      </v-btn>
      <v-btn class="btnText btnColor" elevation="0" color="#E65100" small @click="filterTable()">Rechercher dans la base entière</v-btn>
      <v-btn class="btnText btnColor" elevation="0" color="#E65100" small @click="sortTableByCommune()">Réorganiser par commune</v-btn>
      <v-btn class="btnText btnColor" elevation="0" color="#E65100" small @click="advanced()">Recherche avancée</v-btn>
      <v-tooltip class="tooltipHeight" bottom color="orange darken-4">
        <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="btnText" @click="showFilter()" id="filterBtn" elevation="0" color="#E65100" small outlined><v-icon>mdi-filter</v-icon></v-btn>
     </template>
      <span class="tooltipText">Choisir l'affichage des colonnes du tableau</span>
      </v-tooltip>

      <FilterCard v-if="filter" />
      
    </div>
    <div class="modifyTable">
      <table id="secondTable" class="filterTable">
        <thead v-bind:class="{ stick: this.sticky == true }">
          <tr>
            <th class="controlZ">CONTROLES</th>
            <th
              v-for="(data, index) in keys"
              :key="index"
              v-bind:class="{
                stickier2: data == 'O_TO' && sticky == true,
                headFixed2: data == 'O_TO',
                displayNone: data == 'ROWID' ||data == 'ID' ,
              }"
            >
              {{ data ? modifiedkeys[data] : data }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(columns, index1) in visibleRows"
            :key="index1"
            @click="modify(columns)"
          >
            <td class="fixed controlBox">

              <v-btn elevation="0" class="controlButton" fab color="cyan" @click="localisate(columns[1])" ><v-icon>mdi-map-marker-radius</v-icon></v-btn>

              <v-btn elevation="0" class="controlButton" fab color="red" @click="del(columns[1])" ><v-icon>mdi-trash-can</v-icon></v-btn>

            </td>
            <td
              v-for="(col, index2) in columns"
              :key="index2"
              v-bind:class="{
                fixed2: index2 == 2,
                displayNone: index2 == 0 || index2 == 1 || filtersSelected.includes(col[0]) == false,
              }"
              @dblclick="edit(index1, index2)"
            >
              <span
                v-bind:class="{
                  noActive: test1 == index1 && test2 == index2,
                }"
                >{{ col[1] }}
                <span class="headerInCell" v-if="!col[1]">
                  {{
                    columns[index2][0] != "undefined" ? modifiedkeys[columns[index2][0]] : columns[index2][0]
                  }}</span
                ></span
              ><textarea
                class="updateInput"
                v-bind:class="{ active: test1 == index1 && test2 == index2 }"
                v-bind:placeholder="col[1]"
                v-model.lazy="col[1]"
                rows="4"
                @blur="save(columns, col)"
              ></textarea>
              <select v-if="col[0] == 'CATEGORIE'" @change="saveSelect(columns, col, $event)">
                <option v-for="cate in categories" :key="cate" :value="cate"  :selected="cate === col[1]">{{ cate }} </option>
                
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      v-bind:datas="this.datas"
      v-on:page:update="updatePage"
      v-bind:currentPage="this.currentPage"
      v-bind:pageSize="this.pageSize"
    />
    <div class="message-container" v-if="sendSucess !== ''">{{sendSucess}}</div>
  </div>
</template>

<script>
import { bus } from "../main";
import Pagination from "../components/Pagination";
import ConfirmModal from "../components/ConfirmModal";
import { store, mutations} from "./Store";
import AdvancedSearchModal from "../components/AdvancedSearchModal.vue";
import FilterCard from "./FilterCard.vue";
const mk = {
  ID: "ID",
  ROWID: "ROWID",
  O_TO: "Forme graphique corse",
  O_TO_V1: "Variante forme graphique corse (1)",
  O_TO_V2: "Variante forme graphique corse (2)",
  O_NE: "Nom de l’enquêteur",
  O_NEE: "Nom du locuteur (informateur)",
  O_DATE: "Date de l’enquête (année)",
  O_SON: "Son recueilli",
  O_PHON: "Forme phonique",
  O_ETHN: "Ethnonyme",
  OFF_TO: "Toponyme INSEE",
  OFF_COM: "Nom de commune INSEE",
  OFF_HAM: "Nom du hameau INSEE",
  OFF_INSEE: "INSEE",
  CR_TO: "Toponyme du cadastre rénové",
  CR_NE: "Nom du collecteur",
  CR_NUM: "Numéros de parcelle (CR)",
  CR_NUM2: "Numéros de parcelle (suite) (CR)",
  CR_SEC: "Sections de parcelle (CR)",
  CR_DATE: "Année de rénovation",
  CN_TO: "Toponyme du cadastre napoléonien",
  CN_NE: "Nom du collecteur (CN)",
  CN_NUM: "Numéros de parcelle (CN)",
  CN_SEC: "Sections de parcelle (CN)",
  CN_DATE: "Année (CN)",
  CN_REF: "Référence (CN)",
  PT_TO: "Toponyme du Plan Terrier",
  PT_NE: "Nom du collecteur (PT)",
  PT_DATE: "Année (PT)",
  PT_REF: "Référence des rouleaux (PT)",
  AR_TO: "Toponyme de l’Archive",
  AR_NE: "Nom du collecteur (AR)",
  AR_TYPE: "Type d’archive",
  AR_DATE: "Date de l’archive",
  AR_REF: "Référence de l’archive",
  PU_TO: "Toponyme de source publiée",
  PU_NE: "Nom du collecteur (PU)",
  PU_TYPE: "Type de publication",
  PU_DATE: "Date de la publication",
  PU_REF: "Référence bibliographique",
  ANA_CLAS: "Classification générale",
  ANA_THEMG: "Thème général",
  ANA_THEM: "Thème",
  ANA_SSTH: "Sous-thème",
  ANA_SIGN: "Type de *signifié",
  ANA_LEM1: "Lemme 1",
  ANA_LEM2: "Lemme 2",
  ANA_ETHY: "Etymologie",
  ANA_REFETY: "Référence bibliographique de l’étymologie",
  ANA_DET: "Présence d’un déterminant",
  ANA_RAD: "Radical",
  ANA_SUF: "Préfixe, suffixe, infixe",
  ANA_MC: "Mot composé",
  ANA_REF: "Référent du toponyme",
  ANA_ETHN: "Ethnonyme(2)",
  ANA_ARCH: "Archéologie (oui/non)",
  ANA_GRPER: "Grandes Périodes",
  ANA_OBS: "Observation",
  ANA_OBS1: "OBS1",
  CONFI: "Niveau de confidentialité",
  HP_1: "Code BDLC1",
  HP_2: "Code BDLC2",
  ID_PERS: "Nom du transcripteur",
  VALIDE: "Toponyme validé ?",
  CLIENT: "Visible par le client (nom)",
  CATEGORIE: "Categorie",
};

const categories = [
  "Agrotoponyme",
  "Anthropotoponyme",
  "Ecotoponyme",
  "Ethnotoponyme",
  "Géotoponyme",
  "Hagiotoponyme",
  "Hydrotoponyme",
  "Idéotoponyme / Toponymes abstrait",
  "Métaphore",
  "Mythotoponyme",
  "Néotoponyme",
  "Odonyme",
  "Orotoponyme",
  "Phènomènes atmosphèriques",
  "Phytotoponyme",
  "Toponyme Historique",
  "Toponyme Obscur",
  "Toponyme transféré",
  "Toponymes religieux",
  "Zootoponyme",
];

export default {
  components: {
    Pagination,
    ConfirmModal,
    AdvancedSearchModal,
    FilterCard
  },
  data() {
    return {
      advancedModal: false,
      datas: [],
      form: [],
      lat: "",
      long: "",
      keys: [],
      active_el: 0,
      edited: false,
      test1: "",
      test2: "",
      sticky: false,
      sendSucess: "",
      reload: false,
      reload1: 0,
      datasNonFiltered: [],
      input: "",
      currentPage: 1,
      pageSize: 100,
      visibleRows: [],
      id: "",
      confirmModal: false,
      selectionNonFiltered: [],
      lastSearch: "",
      modifiedkeys: {},
      categories : [],
      filter: false,
      filtersSelected: [],
      lastInput: "",
      updatingPage: false
    };
  },
  methods: {
    advanced(){
      this.advancedModal = true;
    },
    saveSelect(column, datas, event){
      var id = column[1][1];
      var header = datas[0];
      var data = event.target.value;
      this.test1 = 1000;
      this.test2 = 1000;
      var url = process.env.VUE_APP_API_HOST + "putDatas/" + id + "/" + header;
      const putMethod = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ add: data }),
      };
      fetch(url, putMethod)
        .then((response) => response.json())
        .then(() => {
          bus.$emit("boardSave");
          this.sendSucess = "Données enregistrées";
          setTimeout(() => {
            this.sendSucess = "";
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.sendSucess = "Erreur Enregistrement";
          setTimeout(() => {
            this.sendSucess = "";
          }, 2000);
        });
    },

    showFilter() {
      this.filter = !this.filter;
      },

    dataInSelectedOnStart() {
      var object = Object.keys(mk);
      mutations.setFilterCard(object);
      this.filtersSelected = object ;
    },

    updateVisibleRows() {
    this.visibleRows = this.datas.slice(
      (this.currentPage-1) * this.pageSize,
      (this.currentPage-1) * this.pageSize + this.pageSize
    );

    if (this.visibleRows.length === 0 && this.currentPage > 1 && !this.updatingPage) {
      this.updatingPage = true; // Marquer comme mise à jour en cours pour éviter les appels récursifs supplémentaires
      this.updatePage(this.currentPage);
      this.updatingPage = false; // Réinitialiser la variable de contrôle après la mise à jour
    }
  },
    updatePage(pageNumber) {
      this.currentPage = pageNumber;
      this.updateVisibleRows();
    },
    filterTable() {
      if (this.input.length == 0) {
        this.getToponymesByCommunes();
        this.lastSearch = "";
        this.input = "";
        return;
      }
      this.lastSearch = "base";
      this.lastInput = this.input;
      this.datas = [];
      this.datas = this.datasNonFiltered.filter((item) => {
        if (typeof item[2] !== "undefined" && item[2][1] != null)
          return item[2][1]
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              this.input
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            );
        else return;
      });
      this.sortTable();
      bus.$emit("filterMap", this.input.toLowerCase());
      this.updateVisibleRows();
      setTimeout(() => {
        this.input = "";
      }, 500);
    },
    filterTable2() {
      this.datas = [];
      this.datas = this.datasNonFiltered.filter((item) => {
        if (typeof item[2] !== "undefined" && item[2][1] != null)
          return item[2][1]
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              this.lastInput
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            );
        else return;
      });
      this.sortTable();
      bus.$emit("filterMap", this.input.toLowerCase());
      this.updateVisibleRows();
      setTimeout(() => {
        this.input = "";
      }, 500);
    },
    filterTableBySelection() {
      //this.getToponymesByCommunes();
      if (this.input.length == 0) {
        this.getToponymesByCommunes();
        this.lastSearch = "";
        this.input = "";
        return;
      }
      this.lastInput = this.input;
      setTimeout(() => {
        this.datas = [];
        this.datas = this.selectionNonFiltered.filter((item) => {
          if (typeof item[2] !== "undefined" && item[2][1] != null)
            return item[2][1]
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                this.input
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              );
          else return;
        });
        this.lastSearch = "selection";
        bus.$emit("filterMap", this.input.toLowerCase());
        this.sortTable();
        this.updateVisibleRows();
        this.input = "";
      }, 500);
    },
    filterTableBySelection2() {     
      setTimeout(() => {
        this.datas = [];
        this.datas = this.selectionNonFiltered.filter((item) => {
          if (typeof item[2] !== "undefined" && item[2][1] != null)
            return item[2][1]
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                this.lastInput
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              );
          else return;
        });
        this.lastSearch = "selection";
        bus.$emit("filterMap", this.input.toLowerCase());
        this.sortTable();
        this.updateVisibleRows();
        this.input = "";
      }, 500);
    },
    async localisate(id) {


      var response = await fetch(
        process.env.VUE_APP_API_HOST + "getToponymeById/" + id[1]
      );
      var result = await response.json();
      var latitude = result.geometry.coordinates[1];
      var longitude = result.geometry.coordinates[0];
      bus.$emit("localisate", {
        latitude: latitude,
        longitude: longitude,
      });
      
     
    },
    async del(id) {
      this.id = id[1];
      this.confirmModal = true;
    },
    modify(e) {
      this.active_el = e[1][1];
    },
    edit(index1, index2) {
      this.test1 = index1;
      this.test2 = index2;
    },
    save(column, datas) {
      console.log("test")
      var id = column[1][1];
      var header = datas[0];
      var data = datas[1];
      this.test1 = 1000;
      this.test2 = 1000;
      var url = process.env.VUE_APP_API_HOST + "putDatas/" + id + "/" + header;
      const putMethod = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({ add: data }),
      };

      fetch(url, putMethod)
        .then((response) => response.json())
        .then(() => {
          bus.$emit("boardSave");
          this.sendSucess = "Données enregistrées";
          setTimeout(() => {
            this.sendSucess = "";
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.sendSucess = "Erreur Enregistrement";
          setTimeout(() => {
            this.sendSucess = "";
          }, 2000);
        });
    },
    async sortTable() {
      let datasSorted = this.datas.sort((a, b) => {
        return (
          (a[2][1] === null) - (b[2][1] === null) ||
          ("" + a[2][1]).localeCompare(b[2][1], { senitivity: "base" })
        );
      });
      this.datas = [];
      datasSorted.forEach((element) => {
        this.datas.push(element);
      });
      this.updateVisibleRows();
    },
    sortTableByCommune() {
      this.sortTable();
      let datasSorted = this.datas.sort((a, b) => {
        //if (a[12][1].trim().toLowerCase() < b[12][1].trim().toLowerCase()) return -1;
        //if (a[12][1].trim().toLowerCase() > b[12][1].trim().toLowerCase()) return 1;
        return (
          (a[12][1] === null) - (b[12][1] === null) ||
          ("" + a[12][1]).localeCompare(b[12][1], { senitivity: "base" })
        );
      });
      this.datas = [];
      datasSorted.forEach((element) => {
        this.datas.push(element);
      });
      this.updateVisibleRows();
    },
    async getToponymesByCommunes() {
      this.datas = [];
      this.selectionNonFiltered = [];
      let communes = this.selectedCommunes;
      if (communes.length > 100) return;
      await Promise.all(
        communes.map(async (commune) => {
          const response = await fetch(
            process.env.VUE_APP_API_HOST + "getToponymesByCommune/" + commune
          );
          let data = await response.json();
          data.forEach((element) => {
            var array = Object.entries(element.properties);
            this.datas.push(array);
            this.selectionNonFiltered.push(array);
          });
          this.sortTable();
          this.updateVisibleRows();
        })
      );
    },
    async getToponymesByCommunesWithoutUpdateRows() {
      this.datas = [];
      this.selectionNonFiltered = [];
      let communes = this.selectedCommunes;
      if (communes.length > 100) return;
      await Promise.all(
        communes.map(async (commune) => {
          const response = await fetch(
            process.env.VUE_APP_API_HOST + "getToponymesByCommune/" + commune
          );
          let data = await response.json();
          data.forEach((element) => {
            var array = Object.entries(element.properties);
            this.datas.push(array);
            this.selectionNonFiltered.push(array);
          });
        })
      );
    },
    async getAllToponymes() {
      this.datasNonFiltered = [];
      const response2 = await fetch(
        process.env.VUE_APP_API_HOST + "getAllDatas"
      );
      let data2 = await response2.json();
      data2.forEach((element) => {
        var array = Object.entries(element.properties);
        this.datasNonFiltered.push(array);
      });
    },
    async createBoard() {
      const response = await fetch(process.env.VUE_APP_API_HOST + "getkeys");
      let data = await response.json();
      data.forEach((element) => {
        this.keys.push(element);
      });
      this.getToponymesByCommunes();
    },
    async updateBoard() {
      console.log("updateBoard")
      console.log(this.lastSearch)
      this.datas = [];
      this.updateVisibleRows();
      if (this.lastSearch == "base") {
        this.getToponymesByCommunesWithoutUpdateRows();
        await new Promise((resolve) => {
          resolve(this.getAllToponymes());
        });
        await this.filterTable2();
      }
      if (this.lastSearch == "selection") {
        console.log("selection2")
        this.getAllToponymes();
        await new Promise((resolve) => {
          resolve(this.getToponymesByCommunesWithoutUpdateRows());
        });
        await this.filterTableBySelection2();
      }
      if(this.lastSearch == ""){
        console.log("test")
        this.getAllToponymes();
        await new Promise((resolve) => {
          resolve(this.getToponymesByCommunesWithoutUpdateRows());
        });
        await this.filterTableBySelection2();

      }

    },
  },

  async created() {
    this.getAllToponymes();
    this.createBoard();
   
    bus.$on("handleCheckbox", ()=>{
      console.log("handleCheckbox")
      this.keys = store.filtersSelected;
      this.filtersSelected = store.filtersSelected;

    })

    bus.$on("save", async () => {
      this.updateBoard();
    });
    bus.$on("closeAdvanced", async () => {
      this.advancedModal = false;
    });

    bus.$on("delete", async () => {
      this.updateBoard();
    });
    bus.$on("add", () => {
      this.getToponymesByCommunes();
    });
    this.modifiedkeys = mk;
    this.categories = categories;
  },
  computed: {
    selectedCommunes() {
      return store.selectedCommunes;
    },
    
  },
  mounted() {
    this.updateVisibleRows();
     bus.$on("advancedSearch", async (elements)=>{
         this.datas = [];
         elements.forEach(element=>{
           var array = Object.entries(element.properties);
            this.datas.push(array);
         })
          console.log(this.datas)
        this.updateVisibleRows()
      
    });

    this.dataInSelectedOnStart();


    bus.$on("close", () => {
      this.confirmModal = false;
    });
    bus.$on("communesFilter", async () => {
      if (this.selectedCommunes.length == 0) {
        this.datas = [];
        this.visibleRows = [];
      }
      if (this.input.length > 0) {
        return;
      }
      if (this.selectedCommunes.length < 100) {
        this.getToponymesByCommunes();
      } else {
        this.datas = [];
        this.updateVisibleRows();
      }
    });
  },
};
</script>

<style>

@import url('https://fonts.cdnfonts.com/css/cooper-hewitt?styles=34279');

.board {
  width: 43.5vw;
  font-family: 'Cooper Hewitt', sans-serif;
  font-weight: 707;
}

.v-btn {
  font-weight: 707;
}

textarea {
  border: solid 1px;
  width: auto;
}

.tooltipHeight {
  z-index: 70000;
}

.searchInput {
  max-width: 20vw  ;
}

.noActive {
  display: none;
}



.btnColor {
  color: white !important;
}

.controlZ {
  z-index: 10;
}

.controlBox {
  text-align: center;
}

.controlButton {
  color: white !important; 
  z-index: 0 !important;
}

.displayNone {
  display: none;
}

.updateInput {
  display: none;
  max-width: 100px;
  text-align: center;
}

.tooltipText {
  font-weight: 707;
  font-family: 'Cooper Hewitt', semi-bold;
}

td,
th {
  vertical-align: top;
  border-top: 1px solid #E65100;
  padding: 10px;
  min-width: 150px;
  height: 100px;
}

th {
  background-color: #FB8C00;
  position: sticky;
  top: 0;
  height: 100px;
  border-bottom: solid 1px white;
  font-weight: 707;

}

.headFixed2 {
  background-color: #FB8C00;
  position: sticky;
  left: 0;
  z-index: 999;
}

.fixed2 {
  background-color: #FB8C00;
  position: sticky;
  left: 0;
  border-right: solid 1px white;
}

.updateInput.active {
  display: block;
  width: auto;
}

.headerInCell {
  color: darkgray;
}

.searchLabel {
  margin-left: 50px;
  margin-right: 5px;
  font-weight: bolder;
}

.boardControl {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

button {
  margin: 5px;
}

.localisate {
  font-size: 10px;
  margin-top: 10px;
}

.modifyTable {
  overflow: auto;
  width: 100%;
  overflow-x: scroll;
  height: 70vh;
  box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.1);
}

.message-container {
  position: fixed;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100000;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  min-width: 300px;
  text-align: center;
}

.success {
  background: #d4edda;
  color: #155724;
}

.failure {
  background: #f8d7da;
  color: #721c24;
}
</style>